/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagePostDto,
  PostDto,
  PostEditorDto,
} from '../models/index';
import {
    PagePostDtoFromJSON,
    PagePostDtoToJSON,
    PostDtoFromJSON,
    PostDtoToJSON,
    PostEditorDtoFromJSON,
    PostEditorDtoToJSON,
} from '../models/index';

export interface PostsEditorGetRequest {
    page?: number;
}

export interface PostsEditorIdDeleteRequest {
    id: string;
}

export interface PostsEditorIdGetRequest {
    id: string;
}

export interface PostsEditorIdPutRequest {
    id: string;
    postEditorDto: PostEditorDto;
}

export interface PostsEditorPostRequest {
    postEditorDto: PostEditorDto;
}

export interface PostsPublishedGetRequest {
    page?: number;
}

export interface PostsPublishedIdGetRequest {
    id: string;
}

/**
 * 
 */
export class PostsApi extends runtime.BaseAPI {

    /**
     * Get all posts, both published and drafts
     */
    async postsEditorGetRaw(requestParameters: PostsEditorGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagePostDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/editor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePostDtoFromJSON(jsonValue));
    }

    /**
     * Get all posts, both published and drafts
     */
    async postsEditorGet(requestParameters: PostsEditorGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagePostDto> {
        const response = await this.postsEditorGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing post
     */
    async postsEditorIdDeleteRaw(requestParameters: PostsEditorIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postsEditorIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/editor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing post
     */
    async postsEditorIdDelete(requestParameters: PostsEditorIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postsEditorIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get a post, whether it\'s published or not
     */
    async postsEditorIdGetRaw(requestParameters: PostsEditorIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postsEditorIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/editor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     * Get a post, whether it\'s published or not
     */
    async postsEditorIdGet(requestParameters: PostsEditorIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostDto> {
        const response = await this.postsEditorIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing post
     */
    async postsEditorIdPutRaw(requestParameters: PostsEditorIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postsEditorIdPut.');
        }

        if (requestParameters.postEditorDto === null || requestParameters.postEditorDto === undefined) {
            throw new runtime.RequiredError('postEditorDto','Required parameter requestParameters.postEditorDto was null or undefined when calling postsEditorIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/editor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostEditorDtoToJSON(requestParameters.postEditorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing post
     */
    async postsEditorIdPut(requestParameters: PostsEditorIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postsEditorIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new post
     */
    async postsEditorPostRaw(requestParameters: PostsEditorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postEditorDto === null || requestParameters.postEditorDto === undefined) {
            throw new runtime.RequiredError('postEditorDto','Required parameter requestParameters.postEditorDto was null or undefined when calling postsEditorPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/editor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostEditorDtoToJSON(requestParameters.postEditorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new post
     */
    async postsEditorPost(requestParameters: PostsEditorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postsEditorPostRaw(requestParameters, initOverrides);
    }

    /**
     * Get published posts
     */
    async postsPublishedGetRaw(requestParameters: PostsPublishedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagePostDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/posts/published`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePostDtoFromJSON(jsonValue));
    }

    /**
     * Get published posts
     */
    async postsPublishedGet(requestParameters: PostsPublishedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagePostDto> {
        const response = await this.postsPublishedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a published post by id
     */
    async postsPublishedIdGetRaw(requestParameters: PostsPublishedIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postsPublishedIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/posts/published/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     * Get a published post by id
     */
    async postsPublishedIdGet(requestParameters: PostsPublishedIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostDto> {
        const response = await this.postsPublishedIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an RSS feed with published posts
     */
    async rssPostsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rss/posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get an RSS feed with published posts
     */
    async rssPostsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.rssPostsGetRaw(initOverrides);
        return await response.value();
    }

}
